import { checkRoute } from '../utils/common'
export default {
  mounted () {
    localStorage.removeItem('redirectUrl')
    if (this.stepTimeAction) {
      this.stepTimeAction()
    }
    this.initializePlugin()
    localStorage.setItem('redirectUrl', window.location.pathname)
  },
  async beforeRouteLeave (to, from, next) {
    if (from.fullPath === '/canadianterm/survey/pre-application') {
      next()
      return
    }
    localStorage.setItem('redirectUrl', from.path)
    if (this.performIdleAction) {
      await this.performIdleAction()
    }
    if (to.path !== '/pages/login') {
      localStorage.removeItem('redirectUrl')
    }
    this.$idleDetection.stopIdleDetection()
    if (!this.isIdle && to.path === '/pages/login') {
      localStorage.removeItem('redirectUrl')
    }
    if (to.path === '/pages/login') {
      this.$store.dispatch('signUserOut')
      this.$store.commit('setShowApplicationNotification', true)
    }
    next()
  },
  async beforeDestroy () {
    this.$idleDetection.stopIdleDetection()
    this.$idleDetection.isIdle = false
  },
  methods: {
    initializePlugin () {
      this.$idleDetection.startIdleDetection()
      try {
        this.$idleDetection.onIdle(async () => {
          if (!checkRoute()) {
            this.$idleDetection.stopIdleDetection()
            return
          }
          this.isIdle = true
          if (this.performIdleAction) {
            await this.performIdleAction()
            this.idleAction()
          } else {
            this.idleAction()
          }
        })
        this.$idleDetection.onActive(() => {
          this.isIdle = false
        })
      } catch (e) {
        console.log('Error on in plugin timeout', e)
      }
    },
    cleanupPlugin () {
       this.$idleDetection.isIdle = false
       this.$idleDetection.stopIdleDetection()
    },
    idleAction () {
        if (this.isIdle) {
          this.$store.dispatch('signUserOut')
          this.$store.commit('setShowApplicationNotification', true)
          this.$router.push('/pages/login')
          this.$idleDetection.stopIdleDetection()
        }
    }
  }
}
